export const FullArea = ({ children }: {children?: React.ReactNode | React.ReactNode[]}) =>
    <div className="full_area">
        <div className="the_header">
            {children}
        </div>
    </div>

export const Header = ({ children }: {children?: React.ReactNode | React.ReactNode[]}) =>
    <div className="header_area">
        <div className="the_header el_100 no_color">
            {children}
        </div>
    </div>

export const HeaderMenu = ({ children }: {children?: React.ReactNode | React.ReactNode[]}) =>
    <div className="header_area_menu">
        <div className="the_header el_100">
            {children}
        </div>
    </div>

export const Center = ({ children }: {children?: React.ReactNode | React.ReactNode[]}) => {
    return (
        <div className="center_area">
            <div className="the_center">
                {children}
            </div>
        </div>
    );
}

export const CenterNoFooter = ({ children }: {children?: React.ReactNode | React.ReactNode[]}) => {
    return (
        <div className="center_area_no_footer">
            <div className="the_center">
                {children}
            </div>
        </div>
    );

}
export const CenterNoFooterMenu = ({ children }: {children?: React.ReactNode | React.ReactNode[]}) => {
    return (
        <div className="center_area_no_footer_menu">
            <div className="the_center">
                {children}
            </div>
        </div>
    );
}

export const ScrollableCenterNoFooter = ({ children }: {children?: React.ReactNode | React.ReactNode[]}) => {
    return (
        <div className="scrollable_center_no_footer">
            <div className="the_center">
                {children}
            </div>
        </div>
    );
}

export const Footer = ({ children }: {children?: React.ReactNode | React.ReactNode[]}) => {
    return (
        <div className="footer_area">
            <div className="the_footer">
                {children}
            </div>
        </div>
    );
}

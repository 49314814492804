import { Route, Routes } from 'react-router-dom';
import CourseOverView from 'screens/CourseOverview';
import GrammarTrain from 'screens/GrammarTrain';
import LexisMain from 'screens/LexisMain';
import LexisTrain from 'screens/LexisTrain';
import MainScreen from 'screens/MainScreen';
import TopicOverView from 'screens/TopicOverview';
import Train from 'screens/train';
import WordsRoomOverView from 'screens/WordsRoomOverview';

function App() {
  return (
      <Routes>
        <Route path="/" element={<MainScreen /> } />
        <Route path="/train" element={<Train /> } />
        <Route path="/lexis" element={<LexisMain /> } />
        <Route path="/lexis/overview" element={<WordsRoomOverView /> } />
        <Route path="/lexis/train" element={<LexisTrain /> } />

        <Route path="/grammar" element={<CourseOverView /> } />
        <Route path="/grammar/topic" element={<TopicOverView /> } />
        <Route path="/grammar/train" element={<GrammarTrain /> } />
      </Routes>
  );
}

export default App;

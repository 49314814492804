
export const clickOnElement = (id: string) => {
    let element = document.getElementById(id);
    element?.dispatchEvent(new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    }))
}
export const focusOnElement = (id: string) => {
    let element = document.getElementById(id);
    element?.focus();
}

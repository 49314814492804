import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';

import "static/css/colors.css";
import "static/css/zero.css";
import "static/css/global.css";
import "static/css/train.css";
import "static/css/words.css";
import "static/css/loading.css";
import "static/css/menu.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
       <App />
    </BrowserRouter>
);

import { Center, CenterNoFooter, CenterNoFooterMenu, Footer, Header, HeaderMenu } from "components/common";
import TopicTheory from "components/TopicTheory";
import { Requester, ServerStatus } from "js/Requester";
import { emptyTopic, GrammarTrain, Topic, Word, WordsRoom } from "js/types";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import backIco from "static/icons/back.svg";

export default function TopicOverView() {

    const navigate = useNavigate();
    const [loaded, setLoaded] = React.useState<boolean>(false);

    const [topicID, setTopicId] = React.useState<number>(-1);
    const [topic, setTopic] = React.useState<Topic>(emptyTopic());

    const tryToLoadTopic = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const topicId: number | null = Number(urlParams.get("id"));

        if (!topicId) {
            navigate("/lexis");
            return;
        }

        const [status, payload] = await Requester.getTopic(topicId);
        if (status === ServerStatus.error) {
            navigate("/lexis");
            return;
        }
        if (payload) {
            setTopic(payload);
            setLoaded(true);
        }
    }

    React.useEffect(() => {
        tryToLoadTopic();
    }, [])


    const renderedWords = topic?.trainsList
        .map((e: GrammarTrain) =>
            <>
                <div key={e.id} className={"topic_button fl_left"} onClick={() => navigate(`/grammar/train?id=${e.id}`)}>
                    <div className="topic_button_ico">📚</div>
                    <div className="topic_button_title">{e.title}</div>
                </div>
            </>
        );

    if (loaded) return (
        <>
            <HeaderMenu>
                <div className="el_100 bg_col_header">
                    <div className="main_container el_100">

                        <div className="el_100 fl_center">
                            <div className="el_w15 el_100 header_button_area fl_center" onClick={() => navigate(-1)}>
                                <img src={backIco} />
                            </div>
                            <div className="el_100 el_w70 header_title fl_center">
                                📖 {topic?.title}
                            </div>
                            <div className="el_w15 el_100">
                            </div>
                        </div>

                    </div>
                </div>
            </HeaderMenu>

            <CenterNoFooterMenu>
                <div className="main_container scrollable_center_no_footer">
                    <div className="words_rooms_header fl_center">Список тренировок</div>
                    <div className="topiclist_area el_w90">
                        {renderedWords}
                    </div>

                    <TopicTheory text={topic.markdownText} />
                </div>
            </CenterNoFooterMenu>

        </>
    ); else return (
        <div className="loading_train">
            <div className="lds-dual-ring"></div>
            <div className="loading_train_text">Загрузка</div>
        </div>
    );
}

import { CenterNoFooter, CenterNoFooterMenu, Header, HeaderMenu } from "components/common";
import { app } from "js/types";
import { Link } from "react-router-dom";

export default function MainScreen() {
    return (
        <>
            <HeaderMenu>
                <div className="main_container el_100">

                    <div className="el_100 fl_center">
                        <div className="el_100 el_w100 header_title fl_center">
                            {app.name}
                        </div>
                    </div>

                </div>
            </HeaderMenu>

            <CenterNoFooterMenu>
                <div className="main_container scrollable_center_no_footer el_100">
                    <div className="menu_header">
                        Разделы курса:
                    </div>
                    <div className="fl_evenly">
                        <Link to="/lexis" className="course_section fl_center el_w40 vertical_elements">
                            <div className="course_section_img el_60 fl_center">🏀</div>
                            <div className="course_section_title">Лексика</div>
                        </Link>
                        <Link to="/grammar" className="course_section fl_center el_w40 vertical_elements">
                            <div className="course_section_img el_60 fl_center">✏️</div>
                            <div className="course_section_title">Грамматика</div>
                        </Link>
                    </div>
                </div>
            </CenterNoFooterMenu>
        </>
    );
}

import { Center, CenterNoFooter, Footer, Header, ScrollableCenterNoFooter } from "components/common";
import GramarTask from "components/GrammarTask";
import GrammarTaskSummary from "components/GrammarTaskSummary";
import Task from "components/Task";
import TaskSummary from "components/TaskSummary";
import TopicTheory from "components/TopicTheory";
import { checkAnswer, checkGrammarAnswer } from "js/parser/paresr";
import { Requester, ServerStatus } from "js/Requester";
import { focusOnElement } from "js/shortcuts";
import { emptyAnswer, GrammarTask, TaskAnswerType, WordsRoom, WordsRoomTask } from "js/types";
import React from "react";
import { useNavigate } from "react-router-dom";

import crossImage from "static/icons/cross.svg";


export default function GrammarTrain() {

    const navigate = useNavigate();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [tasks, setTasks] = React.useState<GrammarTask[]>([]);
    const numberOfErrors = React.useRef<number>(0);
    const currentTaskNumber = React.useRef<number>(0);

    const [answer, setAnswer] = React.useState<TaskAnswerType>(emptyAnswer());
    const [markdownText, setMarkdownText] = React.useState<string>("");

    const [showTheory, setShowTheory] = React.useState<boolean>(false);

    const tryToLoadTrain = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const trainId: number | null = Number(urlParams.get("id"));

        if (!trainId) {
            navigate("/");
            return;
        }

        const [status, payload] = await Requester.getGramarTrain(trainId);
        if (status === ServerStatus.error) {
            alert("error");
            navigate("/");
            return;
        }
        if (payload) {
            setTasks(payload);
            rerenderProgressBar();
            setLoaded(true);
            const [st, pl] = await Requester.getTrainTheory(trainId);
            if (st === ServerStatus.error) {
                alert("error");
                navigate("/");
                return;
            }
            if (pl) setMarkdownText(pl);
        }
    }

    const handleSubmitAnswerButton = () => {
        if (!answer.ready) return;
        setAnswer({ ...answer, answered: true });
    }

    React.useEffect(() => {
        tryToLoadTrain();
    }, [])

    const rerenderProgressBar = () => {
        let bar = document.getElementById("bar");
        const currentLength = (currentTaskNumber.current + 1) / tasks.length * 100;
        if (bar) bar.style.width = currentLength + "%";
    }

    const currentTaskToEndWhenFailed = () => {
        const task = { ...tasks[currentTaskNumber.current] }
        tasks.splice(currentTaskNumber.current, 1);
        tasks.push(task);
    }

    const showNextTask = () => {
        const right = checkGrammarAnswer(tasks[currentTaskNumber.current], answer);
        console.log("showing next task");

        if (currentTaskNumber.current == tasks.length - 1 && right) {
            rerenderProgressBar();
            //navigate(`/summary?errors=${numberOfErrors.current}`);
            window.location.reload();
            return;
        }


        if (right) {
            rerenderProgressBar();
            currentTaskNumber.current += 1;
        } else {
            numberOfErrors.current += 1;
            currentTaskToEndWhenFailed();
        }
        setAnswer(emptyAnswer());
    }

    if (loaded) return (
        <>
            <Header>
                <div className="main_container el_100">
                    <div className="vertical_elements fl_evenly">
                        <div className="progress_bar_and_exit_cross el_70">
                            <div className="exit_button" onClick={() => setShowTheory(!showTheory)}>
                                {!showTheory ? "📘" : "📖"}
                            </div>
                            <div className="progress_bar_main">
                                <div className="progress_bar_body">
                                    <div className="progress_bar_fill" id="bar"></div>
                                </div>
                            </div>
                            <div className="exit_button" onClick={() => navigate(-1)}>
                                <img
                                    src={crossImage}
                                    width={25}
                                    height={25}
                                    color="#000000"
                                    className="cross_btn" />
                            </div>
                        </div>
                        <div className="task_counter_area el_30">{currentTaskNumber.current}/{tasks.length}</div>
                    </div>
                </div>
            </Header>
            {!showTheory &&
                <>
                    <GramarTask currentTask={tasks[currentTaskNumber.current] as GrammarTask} answer={answer as TaskAnswerType} setAnswer={setAnswer} />

                    <Footer>
                        <div className="vertical_elements fl_center">
                            <div className="main_container">
                                <div id="submit_ans_button"
                                    className={answer.ready ? "footer_button bg_col_ok" : "footer_button bg_col_disabled"}
                                    onClick={handleSubmitAnswerButton}
                                >Продолжить</div>
                            </div>
                        </div >
                    </Footer>

                    <GrammarTaskSummary
                        answer={answer}
                        currentTask={tasks[currentTaskNumber.current]}
                        showNextTask={showNextTask}
                    />
                </>}
            {showTheory &&
                <>
                    <ScrollableCenterNoFooter>
                            <TopicTheory text={markdownText} />
                    </ScrollableCenterNoFooter>
                </>}
        </>
    ); else return (
        <div className="loading_train">
            <div className="lds-dual-ring"></div>
            <div className="loading_train_text">Загрузка</div>
        </div>
    );
}

export const app = {
    name: "LingoLope",
}

export enum TaskKindLexis {
    WORD_SELECT_TEXT_KZ_TO_RU = "WORD_SELECT_TEXT_KZ_TO_RU",
    WORD_SELECT_TEXT_RU_TO_KZ = "WORD_SELECT_TEXT_RU_TO_KZ",

    WORD_WRITE_TEXT_KZ_TO_RU = "WORD_WRITE_TEXT_KZ_TO_RU",
    WORD_WRITE_TEXT_RU_TO_KZ = "WORD_WRITE_TEXT_RU_TO_KZ",

    IMAGE_SELECT_RU_TO_KZ = "IMAGE_SELECT_RU_TO_KZ",
    IMAGE_SELECT_KZ_TO_RU = "IMAGE_SELECT_KZ_TO_RU"
}

export enum TaskKindGrammar {
    CLASSIFY_SELECT = "CLASSIFY_SELECT",
    ENDING_PICK_ALL = "ENDING_PICK_ALL",
    ENDING_WRITE_TRANSLATION = "ENDING_WRITE_TRANSLATION",
    ENDING_SELECT_FULLWORDS = "ENDING_SELECT_FULLWORDS",
    SMALL_TRANSLATE = "SMALL_TRANSLATE",
}

export type TaskAnswerType = {
    payload: string,
    payloadArr: string[],
    ready: boolean,
    answered: boolean,
    wordId: number;
}

export const emptyAnswer = (): TaskAnswerType => {
    return { payload: "", payloadArr: [], ready: false, answered: false, wordId: -1 };
}

export type Image = {
    id: number,
    title: string,
    link: string,
}

export type TaskType = {
    id: number,
    number: number,
    title: string,
    questionText?: string,
    image?: Image,
    rightAnswer: string,
    taskKind: TaskKindLexis,
    templates: string[],
    answer?: string
}

export type TrainType = {
    id: number,
    title: string,
    taskList: TaskType[],
}

export type Word = {
    id: number,
    kazakh: string,
    russian: string,
    image: string,
    sound: string
}

export type WordsRoom = {
    id: number,
    title: string,
    publish: boolean,
    wordList: Word[]
}

export type WordsRoomTask = {
    taskKind: TaskKindLexis,
    title: string,
    questionText: string,
    questionSound: string,
    answerOptions: Word[],
    rightAnswer: string
}

export type GrammarToken = {
    value: string,
    translateToInfValue: string[],
}

export type GrammarTask = {
    taskKind: TaskKindGrammar,
    title: string,
    questionText: string,
    answerOptions: string[],
    answerOptionsExtra: string[],
    rightAnswer: string
    rightAnswerExtra: string,
    rightAnswerShow: string,
    grammarTokens: GrammarToken[],
}

export type GrammarTrain = {
    id: number,
    title: string,
    position: number,
}

export type Topic = {
    id: number,
    title: string,
    markdownText: string,
    position: number,
    trainsList: GrammarTrain[],
}

export const emptyTopic = () : Topic => {
    return {
        id: -1,
        title: "",
        markdownText: "",
        position: -1,
        trainsList: []
    }
}

export type Chapter = {
    id: number,
    title: string,
    position: number,
    topicsList: Topic[],
}

export type WordsChapter = {
    id: number,
    title: string,
    position: number,
    publish: boolean,
    wordsRoomsList: WordsRoom[],
}

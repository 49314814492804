import { checkAnswer, checkGrammarAnswer, compareAgainstTemplates } from "js/parser/paresr";
import { GrammarTask, TaskAnswerType, TaskKindGrammar, WordsRoomTask } from "js/types";
import React from "react";
import { Footer } from "./common";

export default function GrammarTaskSummary({ currentTask, answer, showNextTask }: { currentTask: GrammarTask, answer: TaskAnswerType, showNextTask: () => void }) {

    const [right, setRight] = React.useState(false);

    React.useEffect(() => {
        setRight(checkGrammarAnswer(currentTask, answer));
    }, [answer])

    let modalStyle = "task_summary_area";
    modalStyle += answer.answered ? "" : " task_summary_invisible";
    modalStyle += right ? " bg_col_ok" : " bg_col_incorrect";

    return (
        <>
            <div className={modalStyle}>
                <div className="task_summary_info">
                    {[TaskKindGrammar.ENDING_PICK_ALL].includes(currentTask.taskKind)
                        && <>
                            {right && <> <div className="main_container summary_option">
                                Верно!
                            </div>
                                <div className="main_container summary_option">
                                    {currentTask.rightAnswerShow}
                                </div>
                            </>
                            }
                            {!right && <>
                                <div className="main_container summary_option">
                                    Ошибка!
                                </div>
                                <div className="main_container summary_option">
                                    Ваш ответ: {currentTask.questionText + answer.payload}
                                </div>
                            </>}

                            {!right &&
                                <div className="main_container summary_option">
                                    Правильныо: {currentTask.rightAnswerShow}
                                </div>
                            }


                        </>
                    }
                    {[TaskKindGrammar.ENDING_WRITE_TRANSLATION, TaskKindGrammar.ENDING_SELECT_FULLWORDS].includes(currentTask.taskKind)
                        && <>
                            {right && <> <div className="main_container summary_option">
                                Верно!
                            </div>
                                <div className="main_container summary_option">
                                    {currentTask.rightAnswerShow}
                                </div>
                            </>
                            }
                            {!right && <>
                                <div className="main_container summary_option">
                                    Ошибка!
                                </div>
                                <div className="main_container summary_option">
                                    Ваш ответ: {answer.payload}
                                </div>
                            </>}

                            {!right &&
                                <div className="main_container summary_option">
                                    Правильныо: {currentTask.rightAnswerShow}
                                </div>
                            }


                        </>
                    }

                    {currentTask.taskKind === TaskKindGrammar.CLASSIFY_SELECT
                        && <>
                            {right && <div className="main_container summary_option">
                                Верно!
                            </div>}
                            {!right && <div className="main_container summary_option">
                                Ошибка!
                            </div>}

                            {!right &&
                                <div className="main_container summary_option">
                                    Правильныо: {currentTask.rightAnswer}{currentTask.rightAnswerExtra ? ", " + currentTask.rightAnswerExtra : ""}
                                </div>}

                            {right && currentTask.rightAnswer.toLowerCase() !== answer.payload.toLowerCase() && answer.payload !== "" &&
                                < div className="main_container summary_option">
                                    Верно также: {currentTask.rightAnswer}
                                </div>}

                            <div className="main_container summary_option">
                                Ваш ответ: {answer.payloadArr[0]}{answer.payloadArr[1] ? ", " + answer.payloadArr[1] : ""}
                            </div>
                        </>
                    }
                    {currentTask.taskKind === TaskKindGrammar.SMALL_TRANSLATE
                        && <>
                            {right && <div className="main_container summary_option">
                                Верно!
                            </div>}
                            {!right && <div className="main_container summary_option">
                                Ошибка!
                            </div>}

                            {!right &&
                                <div className="main_container summary_option">
                                    Правильныо: {currentTask.rightAnswerShow}
                                </div>}

                            {right && currentTask.rightAnswerShow.toLowerCase() !== answer.payload.toLowerCase() && answer.payload !== "" &&
                                < div className="main_container summary_option">
                                    Верно также: {currentTask.rightAnswerShow}
                                </div>}

                            <div className="main_container summary_option">
                                Ваш ответ: {answer.payload}
                            </div>
                        </>
                    }
                </div>
                <Footer>
                    <div className="vertical_elements fl_center">
                        <div className="main_container">
                            <div id="next"
                                onClick={showNextTask}
                                className="footer_button bg_col_white"
                            >Продолжить</div>
                        </div>
                    </div >
                </Footer>
            </div >
        </>
    )
}

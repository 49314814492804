import { GrammarTask, TaskAnswerType, TaskKindGrammar, TaskKindLexis, WordsRoomTask } from "js/types";

const allWithStars = (segment: string[]) => {
    for (let i = 0; i < segment.length; ++i) {
        if (!segment[i].startsWith('*')) return false;
    }
    return true;
}

/*
    true = все *, не нашлось слова, сегмент надо удалить
    false = нет совпадений
    word = совпадение, которое найдено
*/
const compareWordAgainstSegment = (word: string, segment: string[]) => {
    let allStars = true;
    for (let i = 0; i < segment.length; ++i) {
        if (segment[i].startsWith('*')) {
            let cleanSegmentItem = segment[i].substring(1);
            if (cleanSegmentItem !== word) {
                if (segment.length === 1) return true;
                else continue;
            } else return segment[i];
        } else {
            allStars = false;
            if (segment[i] !== word) continue;
            else return segment[i];
        }
    }
    if (allStars) return true;
    return false;
}

export const compareAgainstTemplates = (templates: string[], input: string) => {
    let right = false;
    templates.forEach((e: string) => {
        if (compareTranslateTemplate(e, input)) right = true;
    });
    return right;
}

export const compareTranslateTemplate = (template: string, input: string) => {
    template = template.toLowerCase();
    input = input.toLowerCase();
    let segments: string[][] = [];
    let currSeg = "";
    for (let i = 0; i < template.length; ++i) {
        if (template[i] === '(') {
            currSeg = "";
            continue;
        } else if (template[i] === ')') {
            let currSegWithoutExtraSpaces = currSeg.split(" ")
                .filter(e => e !== "").map(e => e.trim()).join(" ");
            segments.push(currSegWithoutExtraSpaces.split(" "));
            continue;
        } else {
            currSeg += template[i];
        }
    }

    const inputWords = input.split(" ").filter(e => e !== "").map(e => e.trim());
    let numberOfChecked = 0;
    let curWordIndex = 0;
    while (segments.length > 0) {
        if (curWordIndex >= inputWords.length) {
            if (segments.length > 0) {
                for (let s = 0; s < segments.length; ++s)
                    if (!allWithStars(segments[s])) return false;
            }
        }
        let word = inputWords[curWordIndex];
        let ans = compareWordAgainstSegment(word, segments[0]);
        if (ans === false) {
            return false;
        } else if (ans === true) {
            segments.shift();
            curWordIndex--;
        } else {
            segments[0].splice(segments[0].indexOf(ans), 1);
            if (segments[0].length === 0) segments.shift();
            numberOfChecked++;
        }
        curWordIndex++;
    }
    if (numberOfChecked == inputWords.length) return true;
    return false;
}

export const checkAnswer = (task: WordsRoomTask, answer: TaskAnswerType): boolean => {
    if ([TaskKindLexis.WORD_SELECT_TEXT_RU_TO_KZ, TaskKindLexis.WORD_SELECT_TEXT_KZ_TO_RU].includes(task.taskKind)) return task.rightAnswer[0] === answer.payload;
    if ([TaskKindLexis.WORD_WRITE_TEXT_KZ_TO_RU, TaskKindLexis.WORD_WRITE_TEXT_RU_TO_KZ].includes(task.taskKind)) {
        for (let i = 0; i < task.rightAnswer.length; ++i) {
            if (task.rightAnswer[i].trim().toUpperCase() === answer.payload.trim().toUpperCase()) return true;
        }
        return false;
    }
    if ([TaskKindLexis.IMAGE_SELECT_RU_TO_KZ, TaskKindLexis.IMAGE_SELECT_KZ_TO_RU].includes(task.taskKind)) return task.rightAnswer[0] === answer.payload;
    return false;
}

export const checkGrammarAnswer = (task: GrammarTask, answer: TaskAnswerType): boolean => {
    if ([TaskKindGrammar.CLASSIFY_SELECT].includes(task.taskKind)) {
        return task.rightAnswer + (task.rightAnswerExtra ? task.rightAnswerExtra : "") === answer.payloadArr[0] + (answer.payloadArr[1] ? answer.payloadArr[1] : "");
    }
    if ([TaskKindGrammar.ENDING_PICK_ALL, TaskKindGrammar.ENDING_WRITE_TRANSLATION, TaskKindGrammar.ENDING_SELECT_FULLWORDS].includes(task.taskKind)) {
        return answer.payload.toLowerCase().trim() === task.rightAnswer.toLowerCase().trim();
    }
    if ([TaskKindGrammar.SMALL_TRANSLATE].includes(task.taskKind)) {
        return compareTranslateTemplate(task.rightAnswer.toLowerCase().trim(), answer.payload.toLowerCase().trim());
    }
    return false;
}

import { checkAnswer, compareAgainstTemplates } from "js/parser/paresr";
import { emptyAnswer, TaskAnswerType, WordsRoomTask } from "js/types";
import React from "react";
import { Footer } from "./common";

export default function TaskSummary({ currentTask, answer, showNextTask }: { currentTask: WordsRoomTask, answer: TaskAnswerType, showNextTask: () => void }) {

    const [right, setRight] = React.useState(false);

    React.useEffect(() => {
        setRight(checkAnswer(currentTask, answer));
    }, [answer])

    let modalStyle = "task_summary_area";
    modalStyle += answer.answered ? "" : " task_summary_invisible";
    modalStyle += right ? " bg_col_ok" : " bg_col_incorrect";

    return (
        <>
            <div className={modalStyle}>
                <div className="task_summary_info">

                    {right && <div className="main_container summary_option">
                        Верно!
                    </div>}
                    {!right && <div className="main_container summary_option">
                        Ошибка!
                    </div>}

                    {!right &&
                        <div className="main_container summary_option">
                            Правильный ответ: {currentTask.rightAnswer[0]}
                        </div>}

                    {right && currentTask.rightAnswer[0].toLowerCase() !== answer.payload.toLowerCase() &&
                        < div className="main_container summary_option">
                            Верно также: {currentTask.rightAnswer[0]}
                        </div>}

                    <div className="main_container summary_option">
                        Ваш ответ: {answer.payload}
                    </div>
                </div>
                <Footer>
                    <div className="vertical_elements fl_center">
                        <div className="main_container">
                            <div id="next"
                                onClick={showNextTask}
                                className="footer_button bg_col_white"
                            >Продолжить</div>
                        </div>
                    </div >
                </Footer>
            </div >
        </>
    )
}

import { Center, CenterNoFooter, CenterNoFooterMenu, Footer, Header, HeaderMenu } from "components/common";
import { Requester, ServerStatus } from "js/Requester";
import { Chapter, Topic, Word, WordsRoom } from "js/types";
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";

import backIco from "static/icons/back.svg";

export default function CourseOverView() {

    const navigate = useNavigate();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [chapters, setChapters] = React.useState<Chapter[]>([]);

    const loadChapters = async () => {
        const [status, payload] = await Requester.getAllChapters();
        if (status === ServerStatus.error) {
            alert("error");
            return;
        }
        if (payload) {
            setChapters(payload);
            setLoaded(true);
        }
    }

    React.useEffect(() => {
        loadChapters();
    }, [])


    if (loaded) return (
        <>
            <HeaderMenu>
                <div className="el_100 bg_col_header">
                    <div className="main_container el_100">

                        <div className="el_100 fl_center">
                            <div className="el_w15 el_100 header_button_area fl_center" onClick={() => navigate("/")}>
                                <img src={backIco} />
                            </div>
                            <div className="el_100 el_w70 header_title fl_center">
                                Грамматика ✏️
                            </div>
                            <div className="el_w15 el_100">
                            </div>
                        </div>

                    </div>
                </div>
            </HeaderMenu>

            <CenterNoFooterMenu>
                <div className="main_container scrollable_center_no_footer">
                    {
                        chapters.map(chap => {
                            return (
                                <>
                                    <div className="words_rooms_header fl_center">{chap.title}</div>
                                    <div className="topiclist_area el_w90">
                                        {
                                            chap.topicsList.map((e: Topic) => <>
                                                <div key={e.id} className={"topic_button fl_left"} onClick={() => navigate(`/grammar/topic?id=${e.id}`)}>
                                                        <div className="topic_button_ico">📖</div>
                                                        <div className="topic_button_title">{e.title}</div>
                                                </div>
                                            </>)
                                        }
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </CenterNoFooterMenu>

        </>
    ); else return (
        <div className="loading_train">
            <div className="lds-dual-ring"></div>
            <div className="loading_train_text">Загрузка</div>
        </div>
    );
}

import { Center, CenterNoFooter, CenterNoFooterMenu, Footer, Header, HeaderMenu } from "components/common";
import { Requester, ServerStatus } from "js/Requester";
import { WordsChapter, WordsRoom } from "js/types";
import React from "react";
import { useNavigate } from "react-router-dom";
import backIco from "static/icons/back.svg";

export default function LexisMain() {

    const navigate = useNavigate();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [allWordsRooms, setAllWordsRooms] = React.useState<WordsRoom[]>([]);

    const [wordsChapters, setWordsChapters] = React.useState<WordsChapter[]>([]);

    const loadWordsChapters = async () => {
        const [status, payload] = await Requester.getAllWordsChapters();
        if (status === ServerStatus.error) {
            alert("error");
            return;
        }
        if (payload) {
            setWordsChapters(payload);
            setLoaded(true);
        }
    }

    //const loadWordsRooms = async () => {
    //    const [status, payload] = await Requester.getAllWordsRooms();
    //    if (status === ServerStatus.error) {
    //        alert("error");
    //        return;
    //    }
    //    if (payload) {
    //        setAllWordsRooms(payload);
    //        setLoaded(true);
    //    }
    //}

    React.useEffect(() => {
        const asyncInit = async () => {
            await loadWordsChapters();
            //await loadWordsRooms();
        }; asyncInit();
    }, [])

    if (loaded) return (
        <>
            <HeaderMenu>
                <div className="el_100 bg_col_header">
                    <div className="main_container el_100">

                        <div className="el_100 fl_center">
                            <div className="el_w15 el_100 header_button_area fl_center" onClick={() => navigate("/")}>
                                <img src={backIco} />
                            </div>
                            <div className="el_100 el_w70 header_title fl_center">
                                Лексика 🏀
                            </div>
                            <div className="el_w15 el_100">
                            </div>
                        </div>

                    </div>
                </div>
            </HeaderMenu>

            <CenterNoFooterMenu>
                <div className="main_container">
                    {
                        wordsChapters.map(chap => 
                                <div key={chap.id}>
                                    <div className="words_rooms_header fl_center">{chap.title}</div>
                                    <div className="topiclist_area el_w90">
                                        {
                                            chap.wordsRoomsList.map((e: WordsRoom) =>
                                                <div key={e.id} className={"topic_button fl_left"} onClick={() => navigate(`/lexis/overview?id=${e.id}`)}>
                                                    <div className="topic_button_ico">🏓</div>
                                                    <div className="topic_button_title">{e.title}</div>
                                                </div>)
                                        }
                                    </div>
                                </div>
                        )
                    }
                </div>
            </CenterNoFooterMenu>
        </>
    ); else return (
        <div className="loading_train">
            <div className="lds-dual-ring"></div>
            <div className="loading_train_text">Загрузка</div>
        </div>
    );
}

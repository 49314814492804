import { Footer, Header } from "components/common";
import Task from "components/Task";
import TaskSummary from "components/TaskSummary";
import { checkAnswer } from "js/parser/paresr";
import { Requester, ServerStatus } from "js/Requester";
import { emptyAnswer, TaskAnswerType, WordsRoom, WordsRoomTask } from "js/types";
import React from "react";
import { useNavigate } from "react-router-dom";

import crossImage from "static/icons/cross.svg";

export default function LexisTrain() {

    const navigate = useNavigate();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [tasks, setTasks] = React.useState<WordsRoomTask[]>([]);
    const numberOfErrors = React.useRef<number>(0);
    const currentTaskNumber = React.useRef<number>(0);

    const [answer, setAnswer] = React.useState<TaskAnswerType>(emptyAnswer());

    const tryToLoadTrain = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const wordsRoomId: number | null = Number(urlParams.get("id"));
        const rank: number | null = Number(urlParams.get("rank"));

        if (!wordsRoomId) {
            navigate("/lexis");
            return;
        }

        let status, payload;
        if (rank === 1) [status, payload] = await Requester.getWordsRoomTrainBronze(wordsRoomId);
        if (rank === 2) [status, payload] = await Requester.getWordsRoomTrainSilver(wordsRoomId);
        if (rank === 3) [status, payload] = await Requester.getWordsRoomTrainGold(wordsRoomId);
        if (status === ServerStatus.error) {
            alert("error");
            navigate("/lexis");
            return;
        }
        if (payload) {
            setTasks(payload);
            rerenderProgressBar();
            setLoaded(true);
        }
    }

    const handleSubmitAnswerButton = () => {
        if (!answer.ready) return;
        setAnswer({ ...answer, answered: true });
    }

    React.useEffect(() => {
        tryToLoadTrain();
    }, [])

    const rerenderProgressBar = () => {
        let bar = document.getElementById("bar");
        const currentLength = (currentTaskNumber.current + 1) / tasks.length * 100;
        if (bar) bar.style.width = currentLength + "%";
    }

    const currentTaskToEndWhenFailed = () => {
        const task = { ...tasks[currentTaskNumber.current] }
        tasks.splice(currentTaskNumber.current, 1);
        tasks.push(task);
    }

    const showNextTask = () => {
        const right = checkAnswer(tasks[currentTaskNumber.current], answer);

        if (currentTaskNumber.current == tasks.length - 1 && right) {
            rerenderProgressBar();
            //navigate(`/summary?errors=${numberOfErrors.current}`);
            window.location.reload();
            return;
        }


        if (right) {
            rerenderProgressBar();
            currentTaskNumber.current += 1;
        } else {
            numberOfErrors.current += 1;
            currentTaskToEndWhenFailed();
        }
        setAnswer(emptyAnswer());
    }

    if (loaded) return (
        <>
            <Header>
                <div className="main_container el_100">
                    <div className="vertical_elements fl_evenly">
                        <div className="progress_bar_and_exit_cross el_70">
                            <div className="progress_bar_main">
                                <div className="progress_bar_body">
                                    <div className="progress_bar_fill" id="bar"></div>
                                </div>
                            </div>
                            <div
                                className="exit_button"
                                onClick={() => navigate(-1)}
                            >
                                <img
                                    src={crossImage}
                                    width={25}
                                    height={25}
                                    color="#000000"
                                    className="cross_btn" />
                            </div>
                        </div>
                        <div className="task_counter_area el_30">{currentTaskNumber.current}/{tasks.length}</div>
                    </div>
                </div>
            </Header>
            <Task currentTask={tasks[currentTaskNumber.current] as WordsRoomTask} answer={answer as TaskAnswerType} setAnswer={setAnswer} />

            <Footer>
                <div className="vertical_elements fl_center">
                    <div className="main_container">
                        <div id="submit_ans_button"
                            className={answer.ready ? "footer_button bg_col_ok" : "footer_button bg_col_disabled"}
                            onClick={handleSubmitAnswerButton}
                        >Продолжить</div>
                    </div>
                </div >
            </Footer>

            <TaskSummary
                answer={answer}
                currentTask={tasks[currentTaskNumber.current]}
                showNextTask={showNextTask}
            />
        </>
    ); else return (
        <div className="loading_train">
            <div className="lds-dual-ring"></div>
            <div className="loading_train_text">Загрузка</div>
        </div>
    );
}

import { Chapter, GrammarTask, Topic, TrainType, WordsChapter, WordsRoom, WordsRoomTask } from "./types";

//const baseUrl: string = "http://192.168.1.114:9090";
//const static_url = "http://192.168.1.114/static_files/";

const baseUrl: string = "https://lingolope.com";
const static_url = "https://admin.lingolope.com/static_files/";

const defaultHeaders = () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    return headers;
}


export enum ServerStatus {
    ok,
    error
}

export const Requester = { 
    getTrain: async (id: number): Promise<[ServerStatus, TrainType | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
        };
        const url = `${baseUrl}/api/public/train/${id}`;

        try {
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as TrainType];
            } else {
                return [ServerStatus.error, null];
            }
        } catch {
            return [ServerStatus.error, null];
        }
    },
    getTrainTheory: async (id: number): Promise<[ServerStatus, string | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
        };
        const url = `${baseUrl}/api/public/trains/theory/${id}`;

        try {
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.text()];
            } else {
                return [ServerStatus.error, null];
            }
        } catch {
            return [ServerStatus.error, null];
        }
    },
    getTopic: async (id: number): Promise<[ServerStatus, Topic | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
        };
        const url = `${baseUrl}/api/public/topics/${id}`;

        try {
            const response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as Topic];
            } else {
                return [ServerStatus.error, null];
            }
        } catch {
            return [ServerStatus.error, null];
        }
    },
    getAllWordsRooms: async (): Promise<[ServerStatus, WordsRoom[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
        };
        const url = `${baseUrl}/api/public/words_rooms/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsRoom[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            return [ServerStatus.error, null];
        }
    },
    getWordsRoom: async (id: number): Promise<[ServerStatus, WordsRoom | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
        };
        const url = `${baseUrl}/api/public/words_rooms/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsRoom];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            return [ServerStatus.error, null];
        }
    },
    getWordsRoomTrainBronze: async (id: number): Promise<[ServerStatus, WordsRoomTask[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
        };
        const url = `${baseUrl}/api/public/words_rooms/train/1/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsRoomTask[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            return [ServerStatus.error, null];
        }
    },
    getWordsRoomTrainSilver: async (id: number): Promise<[ServerStatus, WordsRoomTask[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
        };
        const url = `${baseUrl}/api/public/words_rooms/train/2/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsRoomTask[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            return [ServerStatus.error, null];
        }
    },
    getWordsRoomTrainGold: async (id: number): Promise<[ServerStatus, WordsRoomTask[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
        };
        const url = `${baseUrl}/api/public/words_rooms/train/3/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsRoomTask[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            return [ServerStatus.error, null];
        }
    },
    getGramarTrain: async (id: number): Promise<[ServerStatus, GrammarTask[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
        };
        const url = `${baseUrl}/api/public/grammar/train/${id}`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as GrammarTask[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            return [ServerStatus.error, null];
        }
    },
    getAllChapters: async (): Promise<[ServerStatus, Chapter[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
        };
        const url = `${baseUrl}/api/public/chapters/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as Chapter[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            return [ServerStatus.error, null];
        }
    },
    getAllWordsChapters: async (): Promise<[ServerStatus, WordsChapter[] | null]> => {
        const requestOptions = {
            method: 'POST',
            headers: defaultHeaders(),
        };
        const url = `${baseUrl}/api/public/words_chapters/get_all`;

        let response;
        try {
            response = await fetch(url, requestOptions);
            if (response.status === 200) {
                return [ServerStatus.ok, await response.json() as unknown as WordsChapter[]];
            } else {
                throw Error("Non 200 response code");
            }
        } catch {
            return [ServerStatus.error, null];
        }
    },
    static_url
}

import { clickOnElement } from "js/shortcuts";
import { GrammarTask, TaskAnswerType, TaskKindGrammar, TaskKindLexis, Word, WordsRoomTask } from "js/types";
import React, { Dispatch, SetStateAction } from "react";

export default function GramarTask({ currentTask, answer, setAnswer }: { currentTask: GrammarTask, answer: TaskAnswerType, setAnswer: Dispatch<SetStateAction<TaskAnswerType>> }) {

    return (
        <div className="center_area">
            <div className="the_task">
                <div className="main_container el_100">
                    <div className="vertical_elements el_100 fl_evenly">
                        {
                            [TaskKindGrammar.CLASSIFY_SELECT].includes(currentTask.taskKind)
                            && <ClassifySelectContent currentTask={currentTask} answer={answer} setAnswer={setAnswer} />
                        }
                        {
                            [TaskKindGrammar.ENDING_PICK_ALL].includes(currentTask.taskKind)
                            && <EndingPickAllContent currentTask={currentTask} answer={answer} setAnswer={setAnswer} />
                        }
                        {
                            [TaskKindGrammar.ENDING_WRITE_TRANSLATION].includes(currentTask.taskKind)
                            && <EndingWiriteTranslation currentTask={currentTask} answer={answer} setAnswer={setAnswer} />
                        }
                        {
                            [TaskKindGrammar.ENDING_SELECT_FULLWORDS].includes(currentTask.taskKind)
                            && <EndingSelectWullFords currentTask={currentTask} answer={answer} setAnswer={setAnswer} />
                        }
                        {
                            [TaskKindGrammar.SMALL_TRANSLATE].includes(currentTask.taskKind)
                            && <SmallTranslate currentTask={currentTask} answer={answer} setAnswer={setAnswer} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const ClassifySelectContent = ({ currentTask, answer, setAnswer }: { currentTask: GrammarTask, answer: TaskAnswerType, setAnswer: Dispatch<SetStateAction<TaskAnswerType>> }) => {
    const renderedAnswerOptions = currentTask.answerOptions
        .map((e: string) =>
            <div key={e} className={(answer.payloadArr[0] === e ? "selectable_text_item selectable_text_item_active" : "selectable_text_item") + " fl_center el_w100"}
                onClick={() => {
                    if (answer.answered) return;
                    answer.payload = "";
                    answer.payloadArr[0] = e;
                    if (currentTask.answerOptionsExtra) answer.ready = (answer.payloadArr[0] !== null && answer.payloadArr[1] != null);
                    else answer.ready = answer.payloadArr[0] !== null;
                    setAnswer({ ...answer });
                }}
            >
                <div className="selectable_text_item_text fl_center">
                    <div className="selectable_text_item_ru el_w100 fl_center">{e}</div>
                </div>
            </div>
        );

    const renderedAnswerOptionsExtra = currentTask.answerOptionsExtra == null ? [] : currentTask.answerOptionsExtra
        .map((e: string) =>
            <div key={e} className={(answer.payloadArr[1] === e ? "selectable_text_item selectable_text_item_active" : "selectable_text_item") + " fl_center el_w100"}
                onClick={() => {
                    if (answer.answered) return;
                    answer.payload = "";
                    answer.payloadArr[1] = e;
                    if (currentTask.answerOptionsExtra) answer.ready = (answer.payloadArr[0] != null && answer.payloadArr[1] !== null);
                    setAnswer({ ...answer });
                }}
            >
                <div className="selectable_text_item_text fl_center">
                    <div className="selectable_text_item_ru el_w100 fl_center">{e}</div>
                </div>
            </div>
        );

    return (
        <>
            <div className="task_header el_10">{currentTask.title}</div>

            <div className="task_main_area el_10 vertical_elements fl_center">
                <div className="task_to_translate fl_center">
                    <div className="fl_center">
                        {currentTask.questionText}
                    </div>
                </div>
            </div>

            <div className="task_main_area el_80 el_w90 vertical_elements fl_center">
                {renderedAnswerOptions}
                {renderedAnswerOptionsExtra.length > 0 && <div className="delimiter el_w100"></div>}
                {renderedAnswerOptionsExtra}
            </div>

        </>
    );
}

const Token = ({ val1, val2, val3 }: { val1: string, val2: string, val3: string | null }) => {

    const [visible, setVisible] = React.useState<boolean>(false);

    if (!val3)
        return (
            <div onClick={() => setVisible(!visible)} className={visible ? "popup_tip_shown" : "popup_tip"}>
                {visible ? val2 : val1}
            </div>

        );
    else
        return (
            <div onClick={() => setVisible(!visible)} className={visible ? "popup_tip_shown" : "popup_tip"}>
                {visible ? val3 + ": " + val2 : val1}
            </div>

        );
}

const EndingPickAllContent = ({ currentTask, answer, setAnswer }: { currentTask: GrammarTask, answer: TaskAnswerType, setAnswer: Dispatch<SetStateAction<TaskAnswerType>> }) => {
    const renderedAnswerOptions = currentTask.answerOptions
        .map((e: string) =>
            <div key={e} className={(answer.payload === e ? "selectable_text_item selectable_text_item_active" : "selectable_text_item") + " fl_center el_w40"}
                onClick={() => {
                    if (answer.answered) return;
                    answer.payload = "";
                    answer.payload = e;
                    answer.ready = answer.payload !== null;
                    setAnswer({ ...answer });
                }}
            >
                <div className="selectable_text_item_text fl_center">
                    <div className="selectable_text_item_ru el_w100 fl_center">{e}</div>
                </div>
            </div>
        );
    return (
        <>
            <div className="task_header el_20">{currentTask.title}</div>

            <div className="task_main_area el_20 vertical_elements fl_center el_w100">
                <div className="task_to_translate fl_center el_100 el_w100">
                    <div className="fl_center">
                        <Token val1={currentTask.grammarTokens[0].value} val2={currentTask.grammarTokens[0].translateToInfValue[0]} val3={null} />
                    </div>
                </div>
            </div>

            <div className="task_main_area el_60 el_w90 fl_center fl_wrap">
                {renderedAnswerOptions}
            </div>

        </>
    );
}

const EndingWiriteTranslation = ({ currentTask, answer, setAnswer }: { currentTask: GrammarTask, answer: TaskAnswerType, setAnswer: Dispatch<SetStateAction<TaskAnswerType>> }) => {

    const appendLetter = (e: string) => {
        setAnswer({ ...answer, payload: answer.payload + e, ready: true });
        const textarea = document.getElementById("textArea");
        textarea?.focus();
    }

    const HelperLetters = () => {
        return (
            <div className="letters_helper_area">
                <div className="letter_helper" onClick={() => appendLetter("ә")}>ә</div>
                <div className="letter_helper" onClick={() => appendLetter("ғ")}>ғ</div>
                <div className="letter_helper" onClick={() => appendLetter("қ")}>қ</div>
                <div className="letter_helper" onClick={() => appendLetter("ң")}>ң</div>
                <div className="letter_helper" onClick={() => appendLetter("ө")}>ө</div>
                <div className="letter_helper" onClick={() => appendLetter("ұ")}>ұ</div>
                <div className="letter_helper" onClick={() => appendLetter("ү")}>ү</div>
                <div className="letter_helper" onClick={() => appendLetter("һ")}>һ</div>
                <div className="letter_helper" onClick={() => appendLetter("і")}>і</div>
            </div>
        );
    }

    return (
        <>
            <div className="task_header el_10">{currentTask.title}</div>

            <div className="task_main_area el_20 vertical_elements fl_center el_w100">
                <div className="task_to_translate fl_center el_100 el_w100">
                    <div className="fl_center">
                        <Token val1={currentTask.grammarTokens[0].value} val2={currentTask.grammarTokens[0].translateToInfValue[0]} val3={null} />
                    </div>
                </div>
            </div>
            <div className="task_textarea_area el_30">
                <div className="el_100">

                    <HelperLetters />

                    {currentTask.questionText &&
                        <textarea id="textArea"
                            placeholder="Напишите здесь"
                            value={answer.payload}
                            onKeyDown={e => {
                                if (e.keyCode == 13) {
                                    e.preventDefault();
                                    if (!answer.answered) {
                                        clickOnElement('submit_ans_button');
                                        setAnswer({ ...answer, answered: true });
                                    } else {
                                        clickOnElement('next');
                                    }
                                }
                            }}
                            onChange={e => {
                                if (answer.answered) return;
                                setAnswer({ ...answer, payload: e.target.value, ready: e.target.value.trim().length >= 1 ? true : false });
                            }}
                        />
                    }

                </div>
            </div>

        </>
    );
}

const EndingSelectWullFords = ({ currentTask, answer, setAnswer }: { currentTask: GrammarTask, answer: TaskAnswerType, setAnswer: Dispatch<SetStateAction<TaskAnswerType>> }) => {

    let renderedAnswerOptions;
    renderedAnswerOptions = currentTask.answerOptions
        .map((e: string) =>
            <div key={e} className={(e === answer.payload ? "selectable_text_item selectable_text_item_active" : "selectable_text_item") + " fl_center el_w100"}
                onClick={() => {
                    if (answer.answered) return;
                    setAnswer({ ...answer, payload: e, ready: true });
                }}
            >
                <div className="selectable_text_item_text fl_center">
                    <div className="selectable_text_item_ru el_w100 fl_center">{e}</div>
                </div>
            </div>
        );

    return (
        <>
            <div className="task_header el_10">{currentTask.title}</div>

            <div className="task_main_area el_10 vertical_elements fl_center">
                <div className="task_to_translate fl_center">
                    <div className="fl_center">
                        {currentTask.questionText}
                    </div>
                </div>
            </div>

            <div className="task_main_area el_70 el_w90 vertical_elements fl_center">
                {renderedAnswerOptions}
            </div>

        </>
    );
}

const SmallTranslate = ({ currentTask, answer, setAnswer }: { currentTask: GrammarTask, answer: TaskAnswerType, setAnswer: Dispatch<SetStateAction<TaskAnswerType>> }) => {

    const appendLetter = (e: string) => {
        setAnswer({ ...answer, payload: answer.payload + e, ready: true });
        const textarea = document.getElementById("textArea");
        textarea?.focus();
    }

    const HelperLetters = () => {
        return (
            <div className="letters_helper_area">
                <div className="letter_helper" onClick={() => appendLetter("ә")}>ә</div>
                <div className="letter_helper" onClick={() => appendLetter("ғ")}>ғ</div>
                <div className="letter_helper" onClick={() => appendLetter("қ")}>қ</div>
                <div className="letter_helper" onClick={() => appendLetter("ң")}>ң</div>
                <div className="letter_helper" onClick={() => appendLetter("ө")}>ө</div>
                <div className="letter_helper" onClick={() => appendLetter("ұ")}>ұ</div>
                <div className="letter_helper" onClick={() => appendLetter("ү")}>ү</div>
                <div className="letter_helper" onClick={() => appendLetter("һ")}>һ</div>
                <div className="letter_helper" onClick={() => appendLetter("і")}>і</div>
            </div>
        );
    }

    return (
        <>
            <div className="task_header el_10">{currentTask.title}</div>

            <div className="task_main_area el_20 vertical_elements fl_center el_w100">
                <div className="task_to_translate fl_center el_100 el_w100">
                    <div className="tokens_holder fl_center">
                        {currentTask.grammarTokens.map(e =>
                            e.translateToInfValue
                                ? <Token key={e.value} val1={e.value} val2={e.translateToInfValue[0]} val3={e.translateToInfValue[1]} />
                                : <div key={e.value} className="token_normal_word">{e.value}</div>
                        )}
                    </div>
                </div>
            </div>
            <div className="task_textarea_area el_30">
                <div className="el_100">

                    <HelperLetters />

                    {currentTask.questionText &&
                        <textarea id="textArea"
                            placeholder="Напишите здесь"
                            value={answer.payload}
                            onKeyDown={e => {
                                if (e.keyCode == 13) {
                                    e.preventDefault();
                                    if (!answer.answered) {
                                        clickOnElement('submit_ans_button');
                                        setAnswer({ ...answer, answered: true });
                                    } else {
                                        clickOnElement('next');
                                    }
                                }
                            }}
                            onChange={e => {
                                if (answer.answered) return;
                                setAnswer({ ...answer, payload: e.target.value, ready: e.target.value.trim().length >= 1 ? true : false });
                            }}
                        />
                    }

                </div>
            </div>

        </>
    );
}

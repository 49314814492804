import { Footer, Header } from "components/common";
import { compareAgainstTemplates } from "js/parser/paresr";
import { Requester, ServerStatus } from "js/Requester";
import { clickOnElement, focusOnElement } from "js/shortcuts";
import { emptyAnswer, TaskType } from "js/types";
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import crossImage from "static/icons/cross.svg";

type AnswerType = {
    payload: string,
    ready: boolean,
    answered: boolean,
}

function Train() {

    const navigate = useNavigate();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [tasks, setTasks] = React.useState<TaskType[]>([]);
    const numberOfErrors = React.useRef<number>(0);
    const currentTaskNumber = React.useRef<number>(0);

    const [answer, setAnswer] = React.useState<AnswerType>(emptyAnswer());

    const loadTrain = async () => {
        const [status, payload] = await Requester.getTrain(1);
        if (status === ServerStatus.error) {
            alert("error");
            return;
        }
        if (payload === null || payload?.taskList.length === 0) {
            alert("no tasks");
            return;
        }

        payload.taskList.forEach((e: TaskType) => {
            e.answer = "";
            if (e.image) e.image.link = e.image.title + ".jpg";
        });
        setTasks(payload.taskList);
        setLoaded(true);
    }

    const handleSubmitAnswerButton = () => {
        if (!answer.ready) return;
        setAnswer({ ...answer, answered: true });

        focusOnElement("textArea");
    }

    React.useEffect(() => {
        const asyncInit = async () => {
            await loadTrain();
        }; asyncInit();
    }, [])

    const rerenderProgressBar = () => {
        let bar = document.getElementById("bar");
        const currentLength = (currentTaskNumber.current + 1) / tasks.length * 100;
        if (bar) bar.style.width = currentLength + "%";
    }

    const currentTaskToEndWhenFailed = () => {
        const task = { ...tasks[currentTaskNumber.current] }
        tasks.splice(currentTaskNumber.current, 1);
        tasks.push(task);
    }

    const showNextTask = () => {
        const right = compareAgainstTemplates(tasks[currentTaskNumber.current].templates, answer.payload);

        if (currentTaskNumber.current == tasks.length - 1 && right) {
            rerenderProgressBar();
            navigate(`/summary?errors=${numberOfErrors.current}`);
            return;
        }


        if (right) {
            rerenderProgressBar();
            currentTaskNumber.current += 1;
        } else {
            numberOfErrors.current += 1;
            currentTaskToEndWhenFailed();
        }

        setAnswer(emptyAnswer());
    }

    if (loaded) return (
        <>
            <Header>
                <div className="main_container el_100">
                    <div className="vertical_elements fl_evenly">
                        <div className="progress_bar_and_exit_cross el_70">
                            <div className="progress_bar_main">
                                <div className="progress_bar_body">
                                    <div className="progress_bar_fill" id="bar"></div>
                                </div>
                            </div>
                            <div
                                className="exit_button"
                                onClick={() => navigate("/")}
                            >
                                <img
                                    src={crossImage}
                                    width={25}
                                    height={25}
                                    color="#000000"
                                    className="cross_btn" />
                            </div>
                        </div>
                        <div className="task_counter_area el_30">{currentTaskNumber.current}/{tasks.length}</div>
                    </div>
                </div>
            </Header>
            <Task currentTask={tasks[currentTaskNumber.current] as TaskType} answer={answer as AnswerType} setAnswer={setAnswer} />

            <Footer>
                <div className="vertical_elements fl_center">
                    <div className="main_container">
                        <div id="submit_ans_button"
                            className={answer.ready ? "footer_button bg_col_ok" : "footer_button bg_col_disabled"}
                            onClick={handleSubmitAnswerButton}
                        >Продолжить</div>
                    </div>
                </div >
            </Footer>

            <TaskSummary
                answer={answer}
                currentTask={tasks[currentTaskNumber.current]}
                showNextTask={showNextTask}
            />
        </>
    ); else return (
        <div className="loading_train">
            <div className="lds-dual-ring"></div>
            <div className="loading_train_text">Загрузка</div>
        </div>
    );
}


const Task = ({ currentTask, answer, setAnswer }: { currentTask: TaskType, answer: AnswerType, setAnswer: Dispatch<SetStateAction<AnswerType>> }) => {

    const appendLetter = (e: string) => {
        setAnswer({ ...answer, payload: answer.payload + e, ready: true });
        const textarea = document.getElementById("textArea");
        textarea?.focus();
    }

    const QuestionImage = ({ image }: { image: string }) => {
        return (
            <div className="main_screen_image el_70 fl_center">
                <img src={image} />
            </div>
        );
    }

    const QuestionText = ({ text }: { text: string }) => {
        return (
            <div className="task_to_translate">
                {text}
            </div>
        );
    }

    const HelperLetters = () => {
        return (
            <div className="letters_helper_area">
                <div className="letter_helper" onClick={() => appendLetter("ә")}>ә</div>
                <div className="letter_helper" onClick={() => appendLetter("ғ")}>ғ</div>
                <div className="letter_helper" onClick={() => appendLetter("қ")}>қ</div>
                <div className="letter_helper" onClick={() => appendLetter("ң")}>ң</div>
                <div className="letter_helper" onClick={() => appendLetter("ө")}>ө</div>
                <div className="letter_helper" onClick={() => appendLetter("ұ")}>ұ</div>
                <div className="letter_helper" onClick={() => appendLetter("ү")}>ү</div>
                <div className="letter_helper" onClick={() => appendLetter("һ")}>һ</div>
                <div className="letter_helper" onClick={() => appendLetter("і")}>і</div>
            </div>
        );
    }

    return (
        <div className="center_area">
            <div className="the_task">
                <div className="main_container el_100">
                    <div className="vertical_elements el_100 fl_evenly">
                        <div className="task_header el_5">{currentTask.title}</div>

                        <div className="task_main_area el_65">

                            {currentTask.image && <QuestionImage image={currentTask.image.link} />}

                            {currentTask.questionText && <QuestionText text={currentTask.questionText} />}

                        </div>


                        <div className="task_textarea_area el_30">
                            <div className="el_100">

                                {currentTask.questionText && <HelperLetters />}

                                {currentTask.questionText &&
                                    <textarea id="textArea"
                                        placeholder="Напишите здесь"
                                        value={answer.payload}
                                        onKeyDown={e => {
                                            if (e.keyCode == 13) {
                                                e.preventDefault();
                                                if (!answer.answered) {
                                                    clickOnElement('submit_ans_button');
                                                    setAnswer({ ...answer, answered: true });
                                                } else {
                                                    clickOnElement('next');
                                                }
                                            }
                                        }}
                                        onChange={e => {
                                            if (answer.answered) return;
                                            setAnswer({ ...answer, payload: e.target.value, ready: e.target.value.trim().length >= 1 ? true : false });
                                        }}
                                    />
                                }

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

const TaskSummary = ({ currentTask, answer, showNextTask }: { currentTask: TaskType, answer: AnswerType, showNextTask: () => void }) => {

    const [right, setRight] = React.useState(false);

    React.useEffect(() => {
        setRight(compareAgainstTemplates(currentTask.templates, answer.payload))
    }, [answer])

    let modalStyle = "task_summary_area";
    modalStyle += answer.answered ? "" : " task_summary_invisible";
    modalStyle += right ? " bg_col_ok" : " bg_col_incorrect";

    return (
        <>
            <div className={modalStyle}>
                <div className="task_summary_info">

                    {right && <div className="main_container summary_option">
                        Верно!
                    </div>}
                    {!right && <div className="main_container summary_option">
                        Ошибка!
                    </div>}

                    {!right &&
                        <div className="main_container summary_option">
                            Правильный ответ: {currentTask.rightAnswer}
                        </div>}

                    {right && currentTask.rightAnswer.toLowerCase() !== answer.payload.toLowerCase() &&
                        < div className="main_container summary_option">
                            Верно также: {currentTask.rightAnswer}
                        </div>}

                    <div className="main_container summary_option">
                        Ваш ответ: {answer.payload}
                    </div>
                </div>
                <Footer>
                    <div className="vertical_elements fl_center">
                        <div className="main_container">
                            <div id="next"
                                onClick={showNextTask}
                                className="footer_button bg_col_white"
                            >Продолжить</div>
                        </div>
                    </div >
                </Footer>
            </div >
        </>
    )
}

export default Train;

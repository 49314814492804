import { Center, CenterNoFooter, CenterNoFooterMenu, Footer, Header, HeaderMenu } from "components/common";
import { Requester, ServerStatus } from "js/Requester";
import { Word, WordsRoom } from "js/types";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import backIco from "static/icons/back.svg";

export default function WordsRoomOverView() {

    const navigate = useNavigate();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>("");
    const [wordList, setWordList] = React.useState<Word[]>([]); // selected words

    const [selectedWordId, setSelectedWordId] = React.useState<number>(-1);
    const [roomId, setRoomId] = React.useState<number>(-1);


    const tryToLoadWordsRoom = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const wordsRoomId: number | null = Number(urlParams.get("id"));

        if (!wordsRoomId) {
            navigate("/lexis");
            return;
        }
        setRoomId(wordsRoomId);

        const [status, payload] = await Requester.getWordsRoom(wordsRoomId);
        if (status === ServerStatus.error) {
            navigate("/lexis");
            return;
        }
        if (payload) {
            setTitle(payload.title);
            if (payload.wordList) setWordList(payload.wordList);
            setLoaded(true);
        }
    }

    React.useEffect(() => {
        tryToLoadWordsRoom();
    }, [])


    const renderedWords = wordList
        .map((e: Word) =>
            <div key={e.id} className={(e.id === selectedWordId ? "word_from_list word_from_list_active" : "word_from_list") + " fl_left"}
                onClick={() => {
                    setSelectedWordId(e.id);
                    if (e.sound) {
                        const audio = new Audio(Requester.static_url + "words/sounds/" + e.sound + ".mp3");
                        audio.play();
                    }
                }}>
                <div className="word_from_list_image el_w20 fl_center">
                    {
                        e.image
                            ? <img src={Requester.static_url + "words/images/" + e.image + ".jpg"} />
                            : "🎯"
                    }
                </div>
                <div className="word_from_list_text el_w80">
                    <div className="word_from_list_kz el_w100">{e.kazakh[0]}</div>
                    <div className="word_from_list_ru el_w100">{e.russian[0]}</div>
                </div>
            </div>
        );

    if (loaded) return (
        <>
            <HeaderMenu>
                <div className="el_100 bg_col_header">
                    <div className="main_container el_100">

                        <div className="el_100 fl_center">
                            <div className="el_w15 el_100 header_button_area fl_center" onClick={() => navigate("/lexis")}>
                                <img src={backIco} />
                            </div>
                            <div className="el_100 el_w70 header_title fl_center">
                                🏓 {title}
                            </div>
                            <div className="el_w15 el_100">
                            </div>
                        </div>

                    </div>
                </div>
            </HeaderMenu>

            <CenterNoFooterMenu>
                <div className="main_container scrollable_center_no_footer">

                    <div className="words_rooms_header fl_center">Практика</div>
                    <div className="lexis_trains_area el_w90 fl_space_between">
                        <Link to={`/lexis/train?id=${roomId}&rank=1`} className="lexis_train_button fl_center el_w30 vertical_elements">
                            <div className="lexis_train_button_img el_60 fl_center">🥉</div>
                            <div className="lexis_train_button_title">Тренировка 1</div>
                        </Link>
                        <Link to={`/lexis/train?id=${roomId}&rank=2`} className="lexis_train_button fl_center el_w30 vertical_elements">
                            <div className="lexis_train_button_img el_60 fl_center">🥈</div>
                            <div className="lexis_train_button_title">Тренировка 2</div>
                        </Link>
                        <Link to={`/lexis/train?id=${roomId}&rank=3`} className="lexis_train_button fl_center el_w30 vertical_elements">
                            <div className="lexis_train_button_img el_60 fl_center">🥇</div>
                            <div className="lexis_train_button_title">Тренировка 3</div>
                        </Link>
                    </div>

                    <div className="words_rooms_header fl_center">Список слов</div>
                    <div className="words_area el_w90">
                        {renderedWords}
                    </div>
                </div>
            </CenterNoFooterMenu>

        </>
    ); else return (
        <div className="loading_train">
            <div className="lds-dual-ring"></div>
            <div className="loading_train_text">Загрузка</div>
        </div>
    );
}

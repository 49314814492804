import { Requester } from "js/Requester";
import { clickOnElement } from "js/shortcuts";
import { emptyAnswer, TaskAnswerType, TaskKindLexis, Word, WordsRoomTask } from "js/types";
import React, { Dispatch, SetStateAction } from "react";

import soundIco from "static/icons/sound.svg";

export default function Task({ currentTask, answer, setAnswer }: { currentTask: WordsRoomTask, answer: TaskAnswerType, setAnswer: Dispatch<SetStateAction<TaskAnswerType>> }) {

    return (
        <div className="center_area">
            <div className="the_task">
                <div className="main_container el_100">
                    <div className="vertical_elements el_100 fl_evenly">
                        {
                            [TaskKindLexis.WORD_SELECT_TEXT_RU_TO_KZ, TaskKindLexis.WORD_SELECT_TEXT_KZ_TO_RU].includes(currentTask.taskKind)
                            && <SelectTextContent currentTask={currentTask} answer={answer} setAnswer={setAnswer} />
                        }
                        {
                            [TaskKindLexis.WORD_WRITE_TEXT_RU_TO_KZ, TaskKindLexis.WORD_WRITE_TEXT_KZ_TO_RU].includes(currentTask.taskKind)
                            && <WriteTextContent currentTask={currentTask} answer={answer} setAnswer={setAnswer} />
                        }
                        {
                            [TaskKindLexis.IMAGE_SELECT_KZ_TO_RU, TaskKindLexis.IMAGE_SELECT_RU_TO_KZ].includes(currentTask.taskKind)
                            && <SelectImageContent currentTask={currentTask} answer={answer} setAnswer={setAnswer} />
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

const SelectTextContent = ({ currentTask, answer, setAnswer }: { currentTask: WordsRoomTask, answer: TaskAnswerType, setAnswer: Dispatch<SetStateAction<TaskAnswerType>> }) => {

    let renderedAnswerOptions;
    if (currentTask.taskKind === TaskKindLexis.WORD_SELECT_TEXT_KZ_TO_RU) {
        renderedAnswerOptions = currentTask.answerOptions
            .map((e: Word) =>
                <div key={e.id} className={(e.russian[0] === answer.payload ? "selectable_text_item selectable_text_item_active" : "selectable_text_item") + " fl_center el_w100"}
                    onClick={() => {
                        if (answer.answered) return;
                        setAnswer({ ...answer, payload: e.russian[0], ready: true });
                    }}
                >
                    <div className="selectable_text_item_text fl_center">
                        <div className="selectable_text_item_ru el_w100 fl_center">{e.russian[0]}</div>
                    </div>
                </div>
            );
    } else if (currentTask.taskKind === TaskKindLexis.WORD_SELECT_TEXT_RU_TO_KZ) {
        renderedAnswerOptions = currentTask.answerOptions
            .map((e: Word) =>
                <div key={e.id} className={(e.kazakh[0] === answer.payload ? "selectable_text_item selectable_text_item_active" : "selectable_text_item") + " fl_center el_w100"}
                    onClick={() => {
                        if (answer.answered) return;
                        setAnswer({ ...answer, payload: e.kazakh[0], ready: true });
                        if (e.sound) {
                            const audio = new Audio(Requester.static_url + "words/sounds/" + e.sound + ".mp3");
                            audio.play();
                        }
                    }}
                >
                    <div className="selectable_text_item_text fl_center">
                        <div className="selectable_text_item_ru el_w100 fl_center">{e.kazakh[0]}</div>
                    </div>
                </div>
            );
    }

    return (
        <>
            <div className="task_header el_10">{currentTask.title}</div>

            <div className="task_main_area el_10 vertical_elements fl_center">
                <div className="task_to_translate fl_center">
                    {currentTask.questionSound &&
                        <div className="task_to_translate_image_area fl_center">
                            <img src={soundIco} onClick={() => {
                                if (answer.answered) return;
                                if (currentTask.questionSound) {
                                    const audio = new Audio(Requester.static_url + "words/sounds/" + currentTask.questionSound + ".mp3");
                                    audio.play();
                                }

                            }} />
                        </div>
                    }
                    <div className="fl_center">
                        {currentTask.questionText}
                    </div>
                </div>
            </div>

            <div className="task_main_area el_70 el_w90 vertical_elements fl_center">
                {renderedAnswerOptions}
            </div>

        </>
    );
}


const WriteTextContent = ({ currentTask, answer, setAnswer }: { currentTask: WordsRoomTask, answer: TaskAnswerType, setAnswer: Dispatch<SetStateAction<TaskAnswerType>> }) => {

    const appendLetter = (e: string) => {
        setAnswer({ ...answer, payload: answer.payload + e, ready: true });
        const textarea = document.getElementById("textArea");
        textarea?.focus();
    }

    const HelperLetters = () => {
        return (
            <div className="letters_helper_area">
                <div className="letter_helper" onClick={() => appendLetter("ә")}>ә</div>
                <div className="letter_helper" onClick={() => appendLetter("ғ")}>ғ</div>
                <div className="letter_helper" onClick={() => appendLetter("қ")}>қ</div>
                <div className="letter_helper" onClick={() => appendLetter("ң")}>ң</div>
                <div className="letter_helper" onClick={() => appendLetter("ө")}>ө</div>
                <div className="letter_helper" onClick={() => appendLetter("ұ")}>ұ</div>
                <div className="letter_helper" onClick={() => appendLetter("ү")}>ү</div>
                <div className="letter_helper" onClick={() => appendLetter("һ")}>һ</div>
                <div className="letter_helper" onClick={() => appendLetter("і")}>і</div>
            </div>
        );
    }

    return (
        <>
            <div className="task_header el_10">{currentTask.title}</div>

            <div className="task_main_area el_10 vertical_elements fl_center">
                <div className="task_to_translate fl_center">
                    {currentTask.questionSound &&
                        <div className="task_to_translate_image_area fl_center">
                            <img src={soundIco} onClick={() => {
                                if (answer.answered) return;
                                if (currentTask.questionSound) {
                                    const audio = new Audio(Requester.static_url + "words/sounds/" + currentTask.questionSound + ".mp3");
                                    audio.play();
                                }

                            }} />
                        </div>
                    }
                    <div className="fl_center">
                        {currentTask.questionText}
                    </div>
                </div>

            </div>
            <div className="task_textarea_area el_30">
                <div className="el_100">

                    {currentTask.taskKind === TaskKindLexis.WORD_WRITE_TEXT_RU_TO_KZ && currentTask.questionText && <HelperLetters />}

                    {currentTask.questionText &&
                        <textarea id="textArea"
                            placeholder="Напишите здесь"
                            value={answer.payload}
                            onKeyDown={e => {
                                if (e.keyCode == 13) {
                                    e.preventDefault();
                                    if (!answer.answered) {
                                        clickOnElement('submit_ans_button');
                                        setAnswer({ ...answer, answered: true });
                                    } else {
                                        clickOnElement('next');
                                    }
                                }
                            }}
                            onChange={e => {
                                if (answer.answered) return;
                                setAnswer({ ...answer, payload: e.target.value, ready: e.target.value.trim().length >= 1 ? true : false });
                            }}
                        />
                    }

                </div>
            </div>

        </>
    );
}

const SelectImageContent = ({ currentTask, answer, setAnswer }: { currentTask: WordsRoomTask, answer: TaskAnswerType, setAnswer: Dispatch<SetStateAction<TaskAnswerType>> }) => {

    const wordToImage = (w: Word) => {
        const className = (w.id === answer.wordId ? "task_image_grid_row_item task_image_grid_row_item_active" : "task_image_grid_row_item") + " vertical_elements fl_evenly";
        return (
            <div className={className} onClick={() => {
                if (answer.answered) return;
                setAnswer({ ...answer, ready: true, payload: currentTask.taskKind === TaskKindLexis.IMAGE_SELECT_RU_TO_KZ ? w.kazakh[0] : w.russian[0], wordId: w.id});
                if (w.sound && currentTask.taskKind === TaskKindLexis.IMAGE_SELECT_RU_TO_KZ) {
                    const audio = new Audio(Requester.static_url + "words/sounds/" + w.sound + ".mp3");
                    audio.play();
                }
            }}>
                <img src={Requester.static_url + "words/images/" + w.image + ".jpg"} />
                {currentTask.taskKind === TaskKindLexis.IMAGE_SELECT_RU_TO_KZ && <div className="task_image_grid_item_text"> {w.kazakh[0]} </div>}
            </div>

        )
    }

    return (
        <>
            <div className="task_header el_10">{currentTask.title}</div>

            <div className="task_main_area el_10 vertical_elements fl_center">
                <div className="task_to_translate fl_center">
                    {currentTask.questionSound &&
                        <div className="task_to_translate_image_area fl_center">
                            <img src={soundIco} onClick={() => {
                                if (currentTask.questionSound) {
                                    const audio = new Audio(Requester.static_url + "words/sounds/" + currentTask.questionSound + ".mp3");
                                    audio.play();
                                }

                            }} />
                        </div>
                    }
                    <div className="fl_center">
                        {currentTask.questionText}
                    </div>
                </div>
            </div>

            <div className="task_image_grid el_70 el_w100 vertical_elements fl_evenly">

                <div className="task_image_grid_row fl_evenly el_w100 el_45">
                    {wordToImage(currentTask.answerOptions[0])}
                    {wordToImage(currentTask.answerOptions[1])}
                </div>

                <div className="task_image_grid_row fl_evenly el_w100 el_45">
                    {wordToImage(currentTask.answerOptions[2])}
                    {wordToImage(currentTask.answerOptions[3])}
                </div>

            </div>


        </>
    );
}
